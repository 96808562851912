import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { get, orderBy } from 'lodash';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { IconButton } from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { PaginatedTable } from '../PaginatedTable';
import { LoadingIndicator } from '../LoadingIndicator';
import { ErrorMessage } from '../ErrorMessage';
import { DdaClientData } from '../../App.types';

export const useStyles = makeStyles(() =>
  createStyles({
    enabledCell: {
      width: 128,
    },
  }),
);

export interface DdaTableProps {
  ddaClients: DdaClientData[];
  error?: Error;
  loading?: boolean;
}

const SORTING_COULUMN_IDS: string[] = ['name', 'hostName', 'id', 'ddaCname'];

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

export const DirectDataAccessTable: React.FC<DdaTableProps> = (props) => {
  const { error, loading, ddaClients } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  let content: React.ReactElement = <></>;

  // SEARCH
  const search =
    (query: string) =>
    ({ name, id }: DdaClientData) =>
      name.toLowerCase().includes(query.toLowerCase()) || id.includes(query);

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedTenants = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (sortColumnId && SORTING_COULUMN_IDS.includes(sortColumnId)) {
      return orderBy(
        ddaClients,
        [(client) => get(client, sortColumnId)?.toLowerCase()],
        sortDirection,
      );
    }
    if (sortColumnId === 'isActive') {
      return orderBy(ddaClients, [(client) => client.isActive], sortDirection);
    }
    return ddaClients;
  }, [sortParams, ddaClients]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`ddaList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  const errorMessage = () =>
    (content = (
      <ErrorMessage
        data-testid="fnds-dda-list-error"
        title={t('ddaList.loadError')}
        message={error?.message}
        variant="block"
      />
    ));

  const noDdaClientsMessage = () =>
    (content = <Typography data-testid="fnds-dda-list-no-data">{t('ddaList.noData')}</Typography>);

  const loader = () => (content = <LoadingIndicator data-testid="fnds-dda-list-loading" />);

  // DDA Client Table
  const ddaClientsTable = () => {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('ddaList.searchLabel') }}
        sortedData={sortedTenants}
        search={search}
        noMatchesMessage={(searchExpression) => t('ddaList.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="dda-list-table-header-name"
                columnId="name"
                tooltip={t('ddaList.sortByName')}
              >
                {t('ddaList.name')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="dda-list-table-header-hostname"
                columnId="hostName"
                tooltip={t('ddaList.sortByHostname')}
              >
                {t('ddaList.hostName')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="dda-list-table-header-cname"
                columnId="ddaCname"
                tooltip={t('ddaList.sortByCname')}
              >
                {t('ddaList.ddaCname')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="dda-list-table-header-id"
                columnId="id"
                tooltip={t('ddaList.sortById')}
              >
                {t('ddaList.id')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                tableCellProps={{ className: classes.enabledCell }}
                id="dda-list-table-header-active"
                columnId="isActive"
                tooltip={t('ddaList.sortByIsActive')}
              >
                {t('ddaList.isActive')}
              </SortableTableHeaderCell>
              <TableCell role="columnheader" />
            </TableRow>
          </TableHead>
        )}
        renderRow={({ name, hostName, id, ddaCname, isActive }, index) => (
          <TableRow key={id} aria-rowindex={index + 1} data-testid={`dda-list-table-row-${id}`}>
            <TableCell
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="dda-list-table-header-name"
            >
              {name}
            </TableCell>
            <TableCell
              aria-colindex={2}
              tabIndex={-1}
              aria-describedby="dda-list-table-header-hostname"
            >
              {hostName}
            </TableCell>
            <TableCell
              aria-colindex={3}
              tabIndex={-1}
              aria-describedby="dda-list-table-header-cname"
            >
              {ddaCname}
            </TableCell>
            <TableCell aria-colindex={4} tabIndex={-1} aria-describedby="dda-list-table-header-id">
              {id}
            </TableCell>
            <TableCell
              aria-colindex={5}
              tabIndex={-1}
              aria-describedby="dda-list-table-header-active"
            >
              {isActive ? t('global.yes') : t('global.no')}
            </TableCell>
            <TableCell aria-colindex={6} tabIndex={-1}>
              <LooseIconButton
                aria-label={t('ddaList.moreInformation')}
                component={Link}
                to={`/dda/${id}/dda`}
                state={{
                  name: 'pppasas',
                }}
              >
                <Typography component="span" variant="inherit">
                  {t('global.view')}
                </Typography>
                <ChevronRight />
              </LooseIconButton>
            </TableCell>
          </TableRow>
        )}
      />
    );
  };

  if (error) {
    errorMessage();
  } else if (loading) {
    loader();
  } else if (!ddaClients.length) {
    noDdaClientsMessage();
  } else {
    ddaClientsTable();
  }

  return content;
};
