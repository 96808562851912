import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRestApi from 'hooks/useRestApi';
import { PageTemplate } from 'components/PageTemplate';
import { apiUrl } from 'utils/apiUrl';
import { DirectDataAccessTable } from 'components/DdaList';

export const DirectDataAccessList: React.FunctionComponent = (props) => {
  const { data, error, loading, fetch } = useRestApi(apiUrl('lct', 'dda/clients'));
  const { t } = useTranslation();

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <PageTemplate showTabs title={t('ddaList.pageTitle')} data-testid="dda-list-page">
      <DirectDataAccessTable
        ddaClients={data?.items ?? []}
        error={error}
        loading={loading}
        {...props}
      />
    </PageTemplate>
  );
};
