import React from 'react';
import { Typography } from '@bb-ui/react-library';
import { UnderConstruction } from 'components/UnderConstruction';

export const DdaDefinitionsIpLists: React.FC = (props) => {
  return (
    <Typography>
      <UnderConstruction />
    </Typography>
  );
};
